import { Checker, mixed } from "@recoiljs/refine";

export const userChecker: Checker<any> = mixed();

// full valid schema
// export const userChecker: Checker<UserAtom> = object({
//     auth: object({
//         access: string(),
//         refreshTimestamp: number(),
//         user: object({
//             banned_at: optional(number()),
//             created_at: number(),
//             email: string(),
//             id: string(),
//             is_banned: optional(bool()),
//             last_active_at: optional(number()),
//             role: stringLiterals<IUserRoles>({
//                 AGGREGATOR_USER,
//                 B2B_USER,
//             }),
//             sub_role_id: optional(string()),
//         }),
//     }),
//     profile: object({
//         meta: optional(
//             nullable(
//                 object({
//                     error: optional(string()),
//                 }),
//             ),
//         ),
//         user: optional(
//             nullable(
//                 object({
//                     first_name: optional(string()),
//                     id: string(),
//                     last_name: optional(string()),
//                 }),
//             ),
//         ),
//     }),
// });
