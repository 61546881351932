import {
    ServerStyleSheet,
    StyleSheetManager,
    ThemeProvider as StyledThemeProvider,
} from "styled-components";

import { Theme } from "../theme/theme.constants";
import { useTheme } from "../theme/theme.hooks";
import { StyledGlobal } from "./global.styled";

declare module "styled-components" {
    export interface DefaultTheme extends Theme {}
}

export const StyledProvider = ({
    sheet,
    children,
}: React.PropsWithChildren<{
    sheet: ServerStyleSheet | undefined;
}>) => {
    const { themeProps } = useTheme();

    return (
        <StyleSheetManager sheet={sheet}>
            <StyledThemeProvider theme={themeProps}>
                <>
                    <StyledGlobal />
                    {children}
                </>
            </StyledThemeProvider>
        </StyleSheetManager>
    );
};
