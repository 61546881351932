import { useRouter } from "next/router";
import { useRef } from "react";

import { useGetZendeskJwt } from "lib/api/hooks/use-get-zendesk-jwt.hook";
import { ZENDESK_AUTH_PATH } from "lib/constants/zendesk";
import { useUserType } from "lib/hooks/use-user-type.hook";
import { UserType } from "lib/constants/user-type";
import { getUserFromLS } from "lib/utils/local-storage";

export const ZendeskProvider = () => {
    const formRef = useRef<HTMLFormElement>(null);
    const inputRef = useRef<HTMLInputElement>(null);
    const router = useRouter();
    const userType = useUserType();
    const userFromLS = getUserFromLS();

    useGetZendeskJwt({
        staleTime: 0,
        enabled:
            !!router.query?.return_to &&
            userType === UserType.B2B_USER &&
            !!userFromLS?.auth?.access,
        onSuccess: (res) => {
            if (inputRef.current) {
                inputRef.current.value = res.jwt;
            }

            formRef.current?.submit();

            const { return_to, ...routerQuery } = router.query;
            void router.replace({
                query: { ...routerQuery },
            });
        },
    });

    if (!router.query.return_to) {
        return null;
    }

    return (
        <form
            ref={formRef}
            target="_blank"
            action={`${process.env.NEXT_PUBLIC_ZENDESK_BASE_URL}${ZENDESK_AUTH_PATH}?return_to=${router.query.return_to as string}`}
            method="post"
        >
            <input ref={inputRef} type="hidden" name="jwt"></input>
        </form>
    );
};
