const postfix = "__UUID_SHORT__";

export const COOKIES_NAMES = {
    language: `lang_${postfix}`,
    theme: `theme_${postfix}`,
};

export const COOKIES_ENDLESS_OPTIONS = {
    expires: (() => {
        const tenYears = new Date();
        tenYears.setFullYear(tenYears.getFullYear() + 10);
        return tenYears;
    })(),
    path: "/",
};

export const PASSWORD_REGEXP = /^([A-z\d!"#№$%&'()*+,-./:;<=>?@[\]^_`{|}~])+$/;
