import { useQuery, UseQueryOptions } from "@tanstack/react-query";

import { AuthService } from "lib/api/api";
import { RpcStatus, V1GetZendeskJWTResponse } from "../common/data-contracts";

export const useGetZendeskJwtQueryKey = "get-zendesk-jwt";

type Options = UseQueryOptions<
    V1GetZendeskJWTResponse,
    RpcStatus,
    V1GetZendeskJWTResponse,
    [typeof useGetZendeskJwtQueryKey]
>;

export function useGetZendeskJwt(options?: Options) {
    return useQuery(
        [useGetZendeskJwtQueryKey],
        async () => {
            return (await AuthService.authServiceGetZendeskJwt()).data;
        },
        options,
    );
}
