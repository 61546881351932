const sentryDSN =
    "https://66a93a8f22a44b23a68d8342bce0925c@o4504791600136192.ingest.sentry.io/4504797487300608";
const isProdEnv = process.env.APP_ENV === "production";

const UPLOAD_IMAGE = "upload-image";

const PBI_URL = "https://app.powerbi.com/";

module.exports = {
    UPLOAD_IMAGE,
    isProdEnv,
    sentryDSN,
    PBI_URL,
};
