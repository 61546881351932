export const colors = {
    transparent: "transparent",
    white: "#ffffff",
    black_1: "#222222",
    black_2: "#808080",
    black_3: "#D9D9D9",
    black_4: "#F3F3F3",
    black_5: "#FBFBFB",
    black_6: "#4A4A4A",
    black_7: "#000000",
    black_8: "#D3D3D3",
    black_9: "#F5F7F9",
    green_1: "#17B673",
    green_2: "#DFEDB9",
    green_3: "#59C64F",
    yellow_1: "#F4CE04",
    yellow_2: "#FCF3CA",
    yellow_3: "#FFFCEA",
    yellow_4: "#FFA800",
    purple_1: "#6E43C8",
    purple_2: "#7D4BE8",
    purple_3: "rgba(125, 75, 232, 0.4)",
    red_1: "#F54E4E",
    red_2: "#DE3131",
    red_3: "#FBE0D5",
    red_4: "#FF7E47",
    blue_1: "#289CF0",
    blue_2: "#546683",
    blue_3: "#E7EDFE",
    blue_4: "#F7F9FE",
    blue_5: "#D9E3FF",
    blue_6: "#CDD9E0",
    pink_1: "#FFCAF3",
} as const;

export const IndicatorColors: Record<number, string> = {
    0: "#E7EDFE",
    1: "#F54E4E",
    2: "#D44949",
    3: "#DCA02D",
    4: "#387F95",
    5: "#48AE65",
};

export const shadow = {
    top: "linear-gradient(0deg, rgba(2, 57, 108, 0) 0%, rgba(0, 56, 107, 0.03) 100%)",
    bottom: "linear-gradient(0deg, rgba(0, 56, 107, 0.03) 0%, rgba(2, 57, 108, 0) 100%)",
    left: "linear-gradient(-90deg, rgba(2, 57, 108, 0) 0%, rgba(0, 56, 107, 0.07) 100%)",
    right: "linear-gradient(90deg, rgba(2, 57, 108, 0) 0%, rgba(0, 56, 107, 0.07) 100%)",
};
