import { PermissionsService } from "lib/api/api";
import { useQuery, UseQueryOptions } from "@tanstack/react-query";
import { HttpErrorType } from "../types";

export const useGetUserPermissionsQueryKey = "get-user-permissions";

type Options = Omit<
    UseQueryOptions<
        string[],
        HttpErrorType,
        string[],
        [typeof useGetUserPermissionsQueryKey, string]
    >,
    "queryKey" | "queryFn"
>;

export function useGetUserPermissionsQuery(userId: string, options?: Options) {
    return useQuery(
        [useGetUserPermissionsQueryKey, userId],
        async () => {
            return (
                await PermissionsService.permissionServiceListPermissionActionsGateway(
                    {
                        user_id: userId,
                    },
                )
            ).data;
        },
        options,
    );
}
