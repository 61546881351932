import { COLORFUL_THEME } from "./themes/colorful.theme";
import { DARK_THEME } from "./themes/dark.theme";
import { DEFAULT_THEME } from "./themes/default.theme";

export interface Theme {
    primaryColor: string;
    textColor: string;
    bodyBackground: string;
}

export enum ThemeVariant {
    DEFAULT = "default",
    DARK = "dark",
    COLORFUL = "colorful",
}

export const ThemesProps: Record<ThemeVariant, Theme> = {
    [ThemeVariant.DEFAULT]: DEFAULT_THEME,
    [ThemeVariant.DARK]: DARK_THEME,
    [ThemeVariant.COLORFUL]: COLORFUL_THEME,
};
