import { AppProps } from "next/app";
import dynamic from "next/dynamic";
import { RecoilRoot } from "recoil";

import { RecoilUtilsComponent } from "lib/recoil/components/recoil-utils/recoil-utils";
import { AuthGuardProvider } from "./auth-guard.provider";
import { RedirectToHome } from "./redirect-to-home";
import { ReactQueryProvider } from "./react-query.provider";
import { RecoilSyncStorage } from "./recoil-sync-storage.provider";
import { StyledProvider } from "./styled/styled.provider";
import { ThemeProvider } from "./theme/theme.provider";
import { UserPermissions } from "./user-permissions";
import { IS_WINDOW_DEFINED } from "lib/constants/is-window-defined";
import { SentryComponent } from "./sentry.component";
import { ProvideExternalChangeLanguage } from "./provide-external-change-language";
import { TranslationModeProvider } from "./translation-mode.provider";
import { IS_ENABLED_TRANSLATION_MODE } from "lib/constants/is-enabled-translation-mode";
import { ZendeskProvider } from "./zendesk.provider";

const ToastDynamicProvider = dynamic(
    async () => (await import("./toast.provider")).ToastProvider,
    { ssr: false },
);

const OnboardingDynamicProvider = dynamic(
    async () =>
        (await import("./onboarding/onboarding-provider")).OnboardingProvider,
    { ssr: false },
);

export const CompositeProvider = (props: React.PropsWithChildren<AppProps>) => {
    const { pageProps, children } = props;
    return (
        <>
            <RecoilRoot>
                <RecoilUtilsComponent />
                <RecoilSyncStorage>
                    <ReactQueryProvider>
                        <AuthGuardProvider statusCode={pageProps.statusCode}>
                            <SentryComponent />
                            <ThemeProvider>
                                <StyledProvider sheet={pageProps.sheet}>
                                    {children}
                                </StyledProvider>
                            </ThemeProvider>
                        </AuthGuardProvider>
                        {IS_WINDOW_DEFINED && <UserPermissions />}
                        {IS_WINDOW_DEFINED && <ZendeskProvider />}
                    </ReactQueryProvider>
                    {IS_WINDOW_DEFINED && (
                        <RedirectToHome statusCode={pageProps.statusCode} />
                    )}
                    <OnboardingDynamicProvider />
                </RecoilSyncStorage>
            </RecoilRoot>
            <ToastDynamicProvider />
            {process.env.NEXT_PUBLIC_LOCALIZATION_ENABLE_CI_MODE === "true" && (
                <ProvideExternalChangeLanguage />
            )}
            {IS_ENABLED_TRANSLATION_MODE && <TranslationModeProvider />}
        </>
    );
};
