import { useEffect } from "react";
import * as Sentry from "@sentry/nextjs";

import { useUserData } from "lib/hooks/use-user-data.hook";

export const SentryComponent = () => {
    const { auth } = useUserData();

    useEffect(() => {
        Sentry.setUser({ email: auth?.user.email });
    }, [auth]);

    return null;
};
