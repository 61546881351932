import React, { useEffect, useCallback, useMemo, useState } from "react";
import { useCookies } from "react-cookie";
import { useRecoilState } from "recoil";
import { useMediaQuery } from "@mantine/hooks";
import { useRouter } from "next/router";

import { breakpoints } from "lib/breakpoints";
import { COOKIES_ENDLESS_OPTIONS, COOKIES_NAMES } from "lib/configuration";
import { leftMenuState } from "lib/recoil/atoms/left-menu.atom";
import { Theme, ThemesProps, ThemeVariant } from "./theme.constants";

export interface ITheme {
    themeVariant: ThemeVariant;
    themeProps: Theme;
    setThemeVariant: (theme: ThemeVariant, reload?: boolean) => void;
}

export const ThemeContext = React.createContext<ITheme>({
    themeVariant: ThemeVariant.DEFAULT,
    themeProps: ThemesProps[ThemeVariant.DEFAULT],
    setThemeVariant: () => void 0,
});

export const ThemeProvider = ({ children }: React.PropsWithChildren) => {
    const { themeVariant, themeProps, setThemeVariant } = useThemeState();
    const [, setIsActive] = useRecoilState(leftMenuState);
    const isDesktop = useMediaQuery(`(min-width: ${`${breakpoints.xl}px`})`);
    useEffect(() => {
        if (isDesktop) {
            setIsActive(true);
        }
    }, [isDesktop, setIsActive]);
    return (
        <ThemeContext.Provider
            value={{ themeVariant, themeProps, setThemeVariant }}
        >
            {children}
        </ThemeContext.Provider>
    );
};

const useThemeState = () => {
    const [cookies, setCookie] = useCookies([COOKIES_NAMES.theme]);
    const [themeVariant, setThemeVariantState] = useState(() => {
        const cookieTheme = cookies[COOKIES_NAMES.theme];

        return (
            Object.values(ThemeVariant).find(
                (theme) => theme === cookieTheme,
            ) || ThemeVariant.DEFAULT
        );
    });
    const themeProps = useMemo(() => ThemesProps[themeVariant], [themeVariant]);
    const { reload: locationReload } = useRouter();
    const setThemeVariant = useCallback(
        (newTheme: ThemeVariant, reload?: boolean) => {
            setCookie(COOKIES_NAMES.theme, newTheme, COOKIES_ENDLESS_OPTIONS);
            if (reload) {
                locationReload();
            } else {
                setThemeVariantState(newTheme);
            }
        },
        [locationReload, setCookie],
    );
    return { themeVariant, themeProps, setThemeVariant };
};
