import { useRouter } from "next/router";
import { useCallback } from "react";

export const useChangeLanguage = () => {
    const router = useRouter();

    return useCallback(
        (locale: string) => {
            void router.push(
                {
                    pathname: router.pathname,
                    query: router.query,
                },
                router.asPath,
                { locale },
            );
        },
        [router],
    );
};
