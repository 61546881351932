import { Auth } from "./common/Auth";
import { B2BCompanies } from "./common/B2BCompanies";
import { ApiConfig } from "./common/http-client";
import { Aggregate } from "./common/Aggregate";
import { Integration } from "./common/Integration";
import { Permissions } from "./common/Permissions";
import { Analytics } from "./common/Analytics";
import { fetchInterceptor } from "./interceptor";
import { ClickhouseAnalytics } from "./common/ClickhouseAnalytics";

const apiConfig: ApiConfig = {
    baseUrl: process.env.NEXT_PUBLIC_API_BASE_URL,
};

export const IntegrationService = new Integration(apiConfig);
export const AggregateService = new Aggregate(apiConfig);
export const AuthService = new Auth(apiConfig);
export const B2BCompaniesService = new B2BCompanies(apiConfig);
export const PermissionsService = new Permissions(apiConfig);
export const AnalyticsService = new Analytics(apiConfig);
export const ClickhouseAnalyticsService = new ClickhouseAnalytics(apiConfig);

fetchInterceptor();
