import { useEffect } from "react";

import { useChangeLanguage } from "lib/hooks/use-change-language";

export const ProvideExternalChangeLanguage = () => {
    const changeLanguage = useChangeLanguage();

    useEffect(() => {
        window.localization = {
            changeLanguage,
        };
    }, [changeLanguage]);

    return null;
};
