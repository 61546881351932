import { withDefault } from "@recoiljs/refine";
import { atom } from "recoil";
import { syncEffect } from "recoil-sync";
import { permissionsChecker } from "./permissions.checker";

export const PERMISSIONS_STATE_STORAGE_KEY = "permissions";

export const permissionsState = atom<string[]>({
    key: "permissionsState",
    default: [],
    effects: [
        syncEffect({
            itemKey: PERMISSIONS_STATE_STORAGE_KEY,
            refine: withDefault(permissionsChecker, null),
        }),
    ],
});
