import { withDefault } from "@recoiljs/refine";
import { atom } from "recoil";
import { syncEffect } from "recoil-sync";

import { userChecker } from "./user.checker";
import { UserType } from "lib/constants/user-type";

export const USER_STATE_STORAGE_KEY = "user";

export const userState = atom<UserAtom>({
    key: "userState",
    default: null,
    effects: [
        syncEffect({
            itemKey: USER_STATE_STORAGE_KEY,
            refine: withDefault(userChecker, null),
            ...{
                actionOnFailure_UNSTABLE: "defaultValue",
            },
        }),
    ],
});

export type UserAtom = {
    auth: {
        access?: string;
        refreshTimestamp: number;
        user: {
            banned_at?: string;
            created_at?: string;
            email?: string;
            id?: string;
            is_banned?: boolean;
            last_active_at?: string;
            role: UserType;
            sub_role_id?: string;
            b2b_company_id?: string;
            vendor_id?: string;
        };
    };
    profile: {
        meta?: {
            error?: string;
        } | null;
        user?: {
            first_name?: string;
            id: string;
            last_name?: string;
        } | null;
    };
} | null;
