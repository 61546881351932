var _sentryCollisionFreeGlobalObject = typeof window != "undefined" ? window : typeof global != "undefined" ? global : typeof self != "undefined" ? self : {};
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"fLTgwONBy2mANzTog4Utx"};
_sentryCollisionFreeGlobalObject["__sentryBasePath"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from "@sentry/nextjs";
import { isProdEnv, sentryDSN } from "constants";

const SENTRY_DSN = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN;

const excludedExceptions = [
    "Event `CustomEvent` (type=error) captured as exception", // PBI error from iframe
    "[object Response]",
];

Sentry.init({
    environment: process.env.APP_ENV,
    dsn: SENTRY_DSN || sentryDSN,
    // Adjust this value in production, or use tracesSampler for greater control
    tracesSampleRate: isProdEnv ? 0.1 : 1.0,
    // This sets the sample rate to be 10%. You may want this to be 100% while
    // in development and sample at a lower rate in production
    replaysSessionSampleRate: isProdEnv ? 0.1 : 1.0,
    // If the entire session is not sampled, use the below sample rate to sample
    // sessions when an error occurs.
    replaysOnErrorSampleRate: 1.0,

    integrations: isProdEnv ? [] : [new Sentry.Replay()],

    enabled: process.env.APP_ENV !== "local",
    beforeSend: (event) => {
        const exception = event.exception?.values && event.exception?.values[0];

        if (excludedExceptions.includes(exception?.value)) {
            return null;
        }

        return event;
    },
});
